@font-face {
  font-family: 'CustomClientFont';
  font-style: normal;
  font-weight: 100;
  src: local('Campton'), local('Campton-Thin'), url('./fonts/Campton-Thin.woff2') format('woff2'),
    url('./fonts/Campton-Thin.woff') format('woff');
  ascent-override: 110%;
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: italic;
  font-weight: 100;
  src: local('Campton'), local('Campton-ThinItalic'), url('./fonts/Campton-ThinItalic.woff2') format('woff2'),
    url('./fonts/Campton-ThinItalic.woff') format('woff');
  ascent-override: 110%;
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: normal;
  font-weight: 200;
  src: local('Campton'), local('Campton-ExtraLight'), url('./fonts/Campton-ExtraLight.woff2') format('woff2'),
    url('./fonts/Campton-ExtraLight.woff') format('woff');
  ascent-override: 110%;
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: italic;
  font-weight: 200;
  src: local('Campton'), local('Campton-ExtraLightItalic'),
    url('./fonts/Campton-ExtraLightItalic.woff2') format('woff2'),
    url('./fonts/Campton-ExtraLightItalic.woff') format('woff');
  ascent-override: 110%;
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: normal;
  font-weight: 300;
  src: local('Campton'), local('Campton-Light'), url('./fonts/Campton-Light.woff2') format('woff2'),
    url('./fonts/Campton-Light.woff') format('woff');
  ascent-override: 110%;
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: italic;
  font-weight: 300;
  src: local('Campton'), local('Campton-LightItalic'), url('./fonts/Campton-LightItalic.woff2') format('woff2'),
    url('./fonts/Campton-LightItalic.woff') format('woff');
  ascent-override: 110%;
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: normal;
  font-weight: 400;
  src: local('Campton'), local('Campton-Book'), url('./fonts/Campton-Book.woff2') format('woff2'),
    url('./fonts/Campton-Book.woff') format('woff');
  ascent-override: 110%;
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: italic;
  font-weight: 400;
  src: local('Campton'), local('Campton-BookItalic'), url('./fonts/Campton-BookItalic.woff2') format('woff2'),
    url('./fonts/Campton-BookItalic.woff') format('woff');
  ascent-override: 110%;
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: normal;
  font-weight: 500;
  src: local('Campton'), local('Campton-Medium'), url('./fonts/Campton-Medium.woff2') format('woff2'),
    url('./fonts/Campton-Medium.woff') format('woff');
  ascent-override: 110%;
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: italic;
  font-weight: 500;
  src: local('Campton'), local('Campton-MediumItalic'), url('./fonts/Campton-MediumItalic.woff2') format('woff2'),
    url('./fonts/Campton-MediumItalic.woff') format('woff');
  ascent-override: 110%;
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: normal;
  font-weight: 600;
  src: local('Campton'), local('Campton-SemiBold'), url('./fonts/Campton-SemiBold.woff2') format('woff2'),
    url('./fonts/Campton-SemiBold.woff') format('woff');
  ascent-override: 110%;
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: italic;
  font-weight: 600;
  src: local('Campton'), local('Campton-SemiBoldItalic'), url('./fonts/Campton-SemiBoldItalic.woff2') format('woff2'),
    url('./fonts/Campton-SemiBoldItalic.woff') format('woff');
  ascent-override: 110%;
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: normal;
  font-weight: 700;
  src: local('Campton'), local('Campton-Bold'), url('./fonts/Campton-Bold.woff2') format('woff2'),
    url('./fonts/Campton-Bold.woff') format('woff');
  ascent-override: 110%;
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: italic;
  font-weight: 700;
  src: local('Campton'), local('Campton-BoldItalic'), url('./fonts/Campton-BoldItalic.woff2') format('woff2'),
    url('./fonts/Campton-BoldItalic.woff') format('woff');
  ascent-override: 110%;
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: normal;
  font-weight: 800;
  src: local('Campton'), local('Campton-ExtraBold'), url('./fonts/Campton-ExtraBold.woff2') format('woff2'),
    url('./fonts/Campton-ExtraBold.woff') format('woff');
  ascent-override: 110%;
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: italic;
  font-weight: 800;
  src: local('Campton'), local('Campton-ExtraBoldItalic'), url('./fonts/Campton-ExtraBoldItalic.woff2') format('woff2'),
    url('./fonts/Campton-ExtraBoldItalic.woff') format('woff');
  ascent-override: 110%;
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: normal;
  font-weight: 900;
  src: local('Campton'), local('Campton-Black'), url('./fonts/Campton-Black.woff2') format('woff2'),
    url('./fonts/Campton-Black.woff') format('woff');
  ascent-override: 110%;
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: italic;
  font-weight: 900;
  src: local('Campton'), local('Campton-BlackItalic'), url('./fonts/Campton-BlackItalic.woff2') format('woff2'),
    url('./fonts/Campton-BlackItalic.woff') format('woff');
  ascent-override: 110%;
}

@font-face {
  font-family: 'dripicons-v2';
  src: url('./fonts/dripicons/dripicons-v2.eot');
  src: url('./fonts/dripicons/dripicons-v2.eot?#iefix') format('embedded-opentype'),
    url('./fonts/dripicons/dripicons-v2.woff') format('woff'),
    url('./fonts/dripicons/dripicons-v2.ttf') format('truetype'),
    url('./fonts/dripicons/dripicons-v2.svg#dripicons-v2') format('svg');
  font-weight: normal;
  font-style: normal;
}

